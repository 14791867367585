import React, { useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import './App.css';

const App = () => {
  const [documentType, setDocumentType] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [captchaValid, setCaptchaValid] = useState(false);

  const handleRecaptchaChange = (value) => {
    if (value) {
      setCaptchaValid(true);
    } else {
      setCaptchaValid(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!documentType || !documentNumber) {
      setError('Por favor, completa todos los campos.');
      return;
    }

    if (!captchaValid) {
      alert('Por favor, completa el reCAPTCHA.');
      return;
    }

    setLoading(true);
    const url = `https://hook.us1.make.com/dnqlpfxag5rzl4daad5bxk1red28mhd3?type_id=${documentType}&number=${documentNumber}`;
    axios.get(url)
      .then(response => {
        if (response.data.length === 0 || response.data.some(cert => cert.name === null)) {
          setCertificates([]);
          setError('No se encontraron certificados.');
        } else {
          setCertificates(response.data);
          setError('');
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching the certificates:', error);
        setLoading(false);
        setError('Error al consultar los certificados. Por favor, intenta nuevamente.');
      });
  };

  return (
    <div className="container">    
      <nav className="navbar">
        <img src="logo-mision.png" alt="Left" className="navbar-left" />
        <img src="logo-utp.png" alt="Right" className="navbar-right" />
      </nav><br></br>
      <div className="content">
        <div className="left-panel">
          <h2 className="title">Consulta tus certificados</h2>
          <p className="subtitle">Ingrese su número de documento.</p>
          <img src='personaje.png' alt="Ilustración de personaje" className="image" />
        </div>
        <div className="right-panel">
          <div className='inputs-container'>
            <form onSubmit={handleSubmit} className="form">
              <select
                value={documentType}
                onChange={(e) => setDocumentType(e.target.value)}
                className="input same-width"
              >
                <option value="" disabled>Tipo de Documento</option>
                <option value="ti">T.I (Tarjeta de Identidad)</option>
                <option value="cc">C.C (Cédula de Ciudadanía)</option>
                <option value="ce">C.E (Cédula de Extranjería)</option>
                <option value="pep">PEP (Pasaporte)</option>
                <option value="nes">NES (Número de Estudiante)</option>
              </select>
              
              <input
                type="text"
                value={documentNumber}
                onChange={(e) => setDocumentNumber(e.target.value)}
                placeholder="Documento identidad estudiante"
                className="input same-width"
              />

               <ReCAPTCHA
                sitekey="6LfB_CQqAAAAAGXyZqVmq3bXq40kW7FCvnKmkorb"
                onChange={handleRecaptchaChange}
              />
              <button 
                type="submit" 
                className="button"
              >Consultar</button>
            </form>
          </div>
        </div>
      </div>
      <div className="instructions">
        <a
          href={`${process.env.PUBLIC_URL}/manual.pdf`}
          target="_blank"
          rel="noopener noreferrer"
          className="instructions-link"
        >
          Instrucciones
        </a>
      </div>
      {loading && <p>Cargando...</p>}
      {error && <p className="error">{error}</p>}
      {certificates.length > 0 && (
        <div className="certificates-section">
          <h3 className="certificates-title">Tienes los siguientes certificados</h3>
          <div className="certificates">
            {certificates.map((certificate, index) => (
              <div key={index} className="certificate-card">
                <img src="2912780.png" alt={`Certificado ${index + 1}`} className="certificate-image" />
                <div>
                  <span className="certificate-name">Certificado {index + 1}</span>
                  <a
                    href={`https://hook.us1.make.com/23599xcub5i67pdb2jlnul9nxonhr1yb?name=${certificate.name}`}
                    download
                    target="_blank"
                    className="download-link"
                  >
                    Descargar
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {certificates.length === 0 && !loading && !error && (
        <div className="no-certificates">
          No hay certificados disponibles.
        </div>
      )}
    </div>    
  );
};

export default App;